// Colors
$color-black: #333;
$color-black-op-05: rgba(51, 51, 51, .95);
$color-black-op-50: rgba(51, 51, 51, .50);
$color-gray-light: #f6f6f6;
$color-gray: #dbdbdb;
$color-gray-dark: #595959;
$color-green-main: #417054;
$color-green: #34871D;
$color-yellow: #FFEB3B;
$color-orange: #FFA726;
$color-red: #E53935;
$color-blue: #1E88E5;
$color-grey2: #727272;
$color-grey3: #4A4A4A;
$color-grey4: #979797;
$color-white: #fff;

// Shadows
$shadow: 0 2px 9px 0 rgba(0,0,0,.15);

// Spacing
$spacing-1: 4px;
$spacing-2: 6px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 24px;
$spacing-7: 30px;
$spacing-8: 50px;

// Transitions
$transition-time: 0.3s;
$transition-variant: ease;

// Borders
$border-radius-base: 6px;
$border-radius-ellipse: 30px;
$border-width-base: 1px;

// Fonts
// -- colors
$font-color-dark: $color-black;
$font-color-light: #fff;
$font-color-grey: #a7aca5;
// -- sizes
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-base: 16px;
$font-size-l: 18px;
$font-size-xl: 20px;
$font-size-xxl: 22px;
$font-size-xxxl: 32px;
$font-size-xxxxl: 48px;

// Z-index
// Pls don't set z-index via value and more then that z-index-maximal value
// if some libs has value more change it
$z-index-maximal: 10000;
$z-index-listing-detail-all-photos: 9400;
$z-index-mobile-menu: 9300;
$z-index-mobile-menu-background: 9200;
$z-index-page-popup: 8600;
$z-index-header-dropdown: 8500;
$z-index-header: 8000;
$z-index-mobile-map-switch: 7999;
$z-index-mobile-listing-bar: 7600;
$z-index-dropdown: 7500;
$z-index-listings-filters: 7000;
$z-index-dropdown-button-active: 2;
